<template>
  <div>
    <van-nav-bar
      class="up_vannavbar"
      title="就诊卡升级"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <van-list>
      <div class="jk_card">
        <div class="jk_card_head" style="height: 30px">
          <div class="jk_card_head_title">电子健康卡</div>
          <div class="jk_card_head_extratext">
            共计{{ healthsCardListLength }}张
          </div>
        </div>
        <van-row
          type="flex"
          justify="space-between"
          v-for="(item, index) in healthsCardList"
          :key="index"
        >
          <van-col span="24">
            <div class="card-face-container" @click="routerTo(item)">
              <img
                class="card-bg"
                src="~@/assets/img_health/cardnewbg.png"
                alt=""
              />
              <div class="card-top-info">
                <div class="card-top-org">河南省卫生健康委员会</div>
                <div class="card-top-title">
                  <img src="~@/assets/img_health/icon.png" alt="" />
                  <span>电子健康卡</span>
                </div>
              </div>
              <div class="card-detail-info">
                <div class="card-user-info">
                  <span class="card-user-name">{{ item.name }}</span>
                  <span class="card-user-id">{{
                    item.idNumber.replace(
                      /^(.{4})(?:\d+)(.{4})$/,
                      "$1**********$2"
                    )
                  }}</span>
                </div>
                <div class="card-qrcode">
                  <img
                    class="card-qrcode-logo"
                    src="~@/assets/img_health/logo_.png"
                    alt=""
                  />
                  <div class="card-qrcodeimg">
                    <qrcode :qrurl="item.qrCodeText"></qrcode>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                中华人民共和国国家卫生健康委员会监制
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="jk_card">
        <div class="jk_card_head" style="height: 30px">
          <div class="jk_card_head_title">就诊卡</div>
          <div class="jk_card_head_extratext">
            共计{{ visitsCardListLength }}张
          </div>
        </div>
        <van-row
          type="flex"
          justify="space-between"
          v-for="(item, index) in visitsCardList"
          :key="index"
        >
          <van-col span="24">
            <div class="card-face-container" @click="SetUpgradeCard(item)">
              <img
                class="card-bg"
                src="~@/assets/img_health/jiuzhenka.png"
                alt=""
              />
              <div class="card-top-info">
                <div class="card-top-org-jzk">河南省第二人民医院</div>
                <div class="card-top-title-jzk">
                  <span>{{ item.cardTypeName }}</span>
                </div>
              </div>
              <div class="card-detail-info">
                <div class="card-user-info-jzk">
                  <span class="card-user-name-jzk">{{ item.realName }}</span>
                  <span class="card-user-id-jzk">{{
                    item.idCard.replace(
                      /^(.{4})(?:\d+)(.{4})$/,
                      "$1**********$2"
                    )
                  }}</span>
                </div>
                <div class="card-qrcode">
                  <div class="card-qrcodeimg">
                    <img :src="item.qrcode" />
                  </div>
                </div>
              </div>
              <div class="card-footer card-footer-tipsinfo">
                升级为电子健康卡
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </van-list>
    <van-overlay :show="overlayState">
      <van-loading type="spinner" color="#1989fa">加载中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
// import vueQr from "vue-qr";
import qrcode from "@/components/qrcode.vue";
export default {
  name: "upgradeCard",
  components: { qrcode },
  data() {
    return {
      code: "",
      healthCode: "",
      wechatCode: "",
      visitsCardListLength: 0,
      visitsCardList: [],
      healthsCardListLength: 0,
      healthsCardList: [],
      //遮罩层状态
      overlayState: false,
    };
  },

  created() {
    this.loading = true;

    //判断是否sessionStorage中是否有userId和openId
    var userId = sessionStorage.getItem("userId");
    var openId = sessionStorage.getItem("openId");
    if (!userId || !openId) {
      this.getParameter();
    }
    this.getUserCode();
    if (!sessionStorage.getItem("wechatCode")) {
      this.getUserCode();
      this.$router.push({
        path: "/upgradeCard",
      });
    }
    //从url读取opendid userid
    this.getALLCardList();
  },
  mounted() {},
  methods: {
    //从url读取opendid userid
    getParameter() {
      let url = location.href;
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.split("?")[1];
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      let userId = theRequest.userId;
      let openId = theRequest.openId;
      sessionStorage.setItem("openId", openId);
      sessionStorage.setItem("userId", userId);
    },

    //获取所有卡片列表
    getALLCardList() {
      var userId = sessionStorage.getItem("userId");
      var openId = sessionStorage.getItem("openId");
      if (userId && openId) {
        this.overlayState = true;
        this.$http
          .get("api/visit/user", {
            userId: userId,
            openId: openId,
          })
          .then((res) => {
            this.overlayState = false;
            this.visitsCardList = [];
            this.healthsCardList = [];
            this.visitsCardListLength = 0;
            this.healthsCardListLength = 0;
            if (res && res.status === 200) {
              // 展示就诊卡列表
              if (res.data.visits) {
                this.visitsCardList = res.data.visits;
                this.visitsCardListLength = res.data.visits.length;
              }
              // 展示健康卡卡列表
              if (res.data.healths) {
                this.healthsCardList = res.data.healths;
                this.healthsCardListLength = res.data.healths.length;
              }
              this.loading = false;
              if (
                this.visitsCardList.length &&
                this.visitsCardList.length === 0
              ) {
                this.$toast.loading({
                  message: "没有可升级的就诊卡",
                  forbidClick: true,
                });
              }
            } else {
              this.$toast.loading({
                message: "数据请求失败！",
                forbidClick: true,
              });
            }
          });
      } else {
        this.$toast.loading({
          message: "数据请求失败！",
          forbidClick: true,
        });
      }
    },
    //升级纠正卡为健康卡
    SetUpgradeCard(visitsCardInfo) {
      this.overlayState = true;
      var openid = sessionStorage.getItem("openId");
      var wechatCode = sessionStorage.getItem("wechatCode");
      if (
        wechatCode &&
        openid &&
        visitsCardInfo.patientId &&
        visitsCardInfo.idCard
      ) {
        this.$healthHttpService
          .post("healthapi/api/health/upgrade", {
            wechatCode: wechatCode,
            openId: openid,
            patientId: visitsCardInfo.patientId,
            idCard: visitsCardInfo.idCard,
          })
          .then((res) => {
            this.overlayState = false;
            if (res && res.status === 200) {
              // 展示就诊卡列表
              this.$toast.loading({
                message: "升级成功！",
                forbidClick: true,
              });
              this.getALLCardList();
            } else {
              this.$toast.loading({
                message: "升级失败，建议删除就诊卡后重新注册电子健康卡",
                forbidClick: true,
              });
            }
          });
      } else {
        this.overlayState = false;
        this.$toast.loading({
          message: "升级失败，建议删除就诊卡后重新注册电子健康卡",
          forbidClick: true,
        });
      }
    },

    //返回公众号
    goBack() {
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("openId");
      sessionStorage.removeItem("wechatCode");
      window.location.href = "http://wx.hns2y.com/public/index.html";
    },

    //访问健康卡
    routerTo(cardItem) {
      sessionStorage.setItem("card_cardItem", JSON.stringify(cardItem));
      this.$router.push("/cardInformation");
    },

    /**
     * 获取平台新增卡授权码
     */
    getUserCode() {
      // 缓存里没有说明还没有跳转
      let wechatCode = "";
      let url = location.href;
      if (url.indexOf("wechatCode") === -1) {
        //http://mu2xfbao.shenzhuo.vip:18085/index.html#/upgradeCard
        //let redirect_uri = "http%3A%2F%2Fmu2xfbao.shenzhuo.vip%3A18085%2Findex.html%23%2FaddCardOne";
        // eslint-disable-next-line no-undef
        let redirectUrl = `${domain}index.html%23%2FupgradeCard`;
        window.location.href = `https://health.tengmed.com/open/getUserCode?apiVersion=2&redirect_uri=${redirectUrl}`;
      } else {
        // 从url里面取wechatCode
        wechatCode = url.split("?")[1].split("#")[0].split("=")[1];
        // url没有说明还没跳转
        if (wechatCode !== null || wechatCode !== "") {
          sessionStorage.setItem("wechatCode", wechatCode);
        }
      }
    },

    //通过url提取参数
    getUrlCode() {
      var userId = sessionStorage.getItem("userId");
      var openId = sessionStorage.getItem("openId");
      if (!userId && !openId) {
        this.getParameter();
      }
      var url = location.hash.split("?")[1];
      if (url) {
        let strs = url.split("&");
        var theRequest = new Object();
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
        return theRequest;
      }
    },
  },
};
</script>

<style scoped>
.jk_card {
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  margin: 12px;
  background-color: #ffffff;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  border-color: #c8c7cc;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  overflow: hidden;
}
.jk_card_head {
  display: flex;
  height: 30px;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding: 3px 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom-color: #c8c7cc;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.jk_card_head_title {
  font-size: 12px;
  color: #555;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jk_card_head_extratext {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  margin-left: 6px;
  font-size: 11px;
  text-align: right;
  color: #999;
}

.up_vannavbar {
  margin: -0.2rem;
  margin-bottom: 10px;
  background-color: rgb(20, 180, 175);
  color: #fff;
}
::v-deep .van-nav-bar__title {
  color: #fff !important;
}
::v-deep .van-icon {
  color: #fff;
}
::v-deep .van-nav-bar__text {
  color: #fff;
}

.card-face-container {
  height: 3.5rem;
  width: 6.2rem;
  margin: 0 auto;
  position: relative;
  color: #000000;
  margin-bottom: 10px;
  margin-top: 10px;
}

.card-face-container .card-bg {
  height: 100%;
  width: 100%;
}

.card-face-container .card-top-info {
  position: absolute;
  top: 0.23rem;
  /* width: calc(100% - 0.27rem - 0.34rem); */
  width: 100%;
  padding: 0 0.27rem 0 0.34rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-face-container .card-top-info .card-top-org-jzk {
  font-size: 0.24rem;
  line-height: 0.25rem;
}
.card-face-container .card-top-info .card-top-title-jzk {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  line-height: 0.42rem;
  color: #2b2b2b;
  margin-right: 0.2rem;
}

.card-face-container .card-top-info .card-top-org {
  font-size: 0.18rem;
  line-height: 0.25rem;
}

.card-face-container .card-top-info .card-top-title {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #2b2b2b;
}

.card-face-container .card-top-info .card-top-title img,
.card-face-container .card-top-info .card-top-title-jzk img {
  height: 0.55rem;
  width: 0.55rem;
  margin-right: 0.08rem;
}

.card-face-container .card-detail-info {
  position: absolute;
  top: 1.24rem;
  /* width: calc(100% - 0.34rem - 0.15rem); */
  width: 100%;
  padding: 0 0.15rem 0 0.34rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.card-face-container .card-detail-info .card-user-info-jzk {
  font-size: 0.36rem;
  color: #2b2b2b;
  text-align: start;
  margin-bottom: 0.08rem;
}

.card-face-container .card-detail-info .card-user-info-jzk .card-user-name-jzk {
  height: 0.5rem;
}

.card-face-container .card-detail-info .card-user-info-jzk .card-user-id-jzk {
  font-size: 0.3rem;
  line-height: 0.3rem;
  display: block;
  padding-top: 0.12rem;
}

.card-face-container .card-detail-info .card-user-info {
  font-size: 0.36rem;
  color: #2b2b2b;
  text-align: start;
}

.card-face-container .card-detail-info .card-user-info .card-user-name {
  height: 0.5rem;
}

.card-face-container .card-detail-info .card-user-info .card-user-id {
  font-size: 0.3rem;
  line-height: 0.3rem;
  display: block;
  padding-top: 0.12rem;
}

.card-face-container .card-detail-info .card-qrcode {
  background: #ffffff;
  padding: 0.04rem;
  position: relative;
}

.card-face-container .card-detail-info .card-qrcode .card-qrcode-logo {
  height: 0.44rem;
  width: 0.44rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-face-container .card-detail-info .card-qrcode img,
.card-face-container .card-detail-info .card-qrcode .card-qrcodeimg {
  height: 1.54rem;
  width: 1.54rem;
  display: inline-block;
}

.card-face-container .card-footer {
  position: absolute;
  font-size: 0.18rem;
  bottom: 0.16rem;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  letter-spacing: 0;
  line-height: 0.25rem;
}
.card-footer-tipsinfo {
  border: 1px solid red;
  border-radius: 5px;
  padding: 1px 0.52rem;
  color: red;
}
</style>
